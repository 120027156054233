import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const SchoolMaterial: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 511.999 511.999" {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="m282.291 215.968h67.835l60.181-166.258c1.163-3.203.425-6.293-3.316-7.653l-48.531-17.659c-3.345-1.22-6.378-.113-7.767 3.628z"
        fill="#407093"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m319.851 215.968h30.275l60.181-166.258c1.163-3.203.425-6.293-3.316-7.653l-26.76-9.723c3.742 1.36 4.479 4.422 3.316 7.625z"
        fill="#365e7d"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m355.03 202.56 5.16-14.202h-.028l-14.967-5.442c-3.912-1.417-8.249.596-9.667 4.507-1.417 3.94.595 8.249 4.536 9.695zm10.942-30.076 5.159-14.174h-.028l-29.934-10.915c-3.913-1.416-8.25.597-9.667 4.537-1.417 3.911.595 8.249 4.507 9.666zm10.942-30.049 5.159-14.202h-.028l-14.967-5.441c-3.912-1.418-8.249.595-9.667 4.506-1.418 3.941.596 8.25 4.536 9.667zm10.942-30.076 5.16-14.174h-.028l-29.935-10.913c-3.912-1.417-8.249.595-9.667 4.535-1.417 3.912.595 8.249 4.508 9.667zm10.942-30.049 5.159-14.201h-.028l-14.967-5.442c-3.912-1.418-8.249.595-9.667 4.507-1.417 3.941.595 8.249 4.535 9.666z"
        fill="#2b4d66"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m196.341 215.968h58.113v-165.86l-29.17-2.609-28.943 2.609z"
        fill="#fc7070"
        fillRule="evenodd"
      />
      <path d="m232.967 50.108v165.86h-15.138v-165.86z" fill="#d15a63" />
      <path
        clipRule="evenodd"
        d="m196.341 50.108 23.047-45.525c3.146-6.265 9.014-5.953 12.019 0l23.046 45.554z"
        fill="#ffe07d"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m361.04 255.342h62.506c23.414 0 42.549 19.136 42.549 42.55v84.333c0 23.388-19.135 42.55-42.549 42.55h-62.506v-31.919h62.506c5.839 0 10.63-4.791 10.63-10.631v-84.333c0-5.84-4.792-10.63-10.63-10.63h-62.506z"
        fill="#ffc250"
        fillRule="evenodd"
      />
      <path
        d="m88.083 31.654c-3.94 1.418-8.249-.595-9.666-4.535-1.418-3.912.595-8.249 4.507-9.667 9.581-3.486 19.729-2.75 28.291 1.247 8.589 4.025 15.676 11.312 19.163 20.893l35.746 98.167c1.417 3.911-.624 8.248-4.535 9.667-3.912 1.417-8.249-.597-9.667-4.536l-35.717-98.14c-2.069-5.641-6.265-9.949-11.367-12.33-5.104-2.382-11.114-2.835-16.755-.766z"
        fill="#4a80aa"
      />
      <g clipRule="evenodd" fillRule="evenodd">
        <path
          d="m91.569 41.206-12.131-33.307c-.709-1.956-2.92-2.977-4.876-2.268l-26.136 9.524c-1.984.708-3.005 2.892-2.296 4.876l12.133 33.279z"
          fill="#90d8f9"
        />
        <path d="m119.294 221.014h37.702l-65.427-179.808-33.307 12.105z" fill="#6bbef6" />
        <path
          d="m366.086 464.121c0 26.334-21.544 47.878-47.879 47.878h-170.226c-26.307 0-47.85-21.544-47.85-47.878 0-80.678 0-161.381 0-242.087 0-3.345 2.721-6.066 6.038-6.066h253.879c3.316 0 6.038 2.722 6.038 6.066z"
          fill="#ffe07d"
        />
        <path
          d="m366.086 464.121c0 26.334-21.544 47.878-47.879 47.878h-30.275c26.335 0 47.879-21.544 47.879-47.878 0-80.678 0-161.381 0-242.087 0-3.345-2.721-6.066-6.038-6.066h30.275c3.316 0 6.038 2.722 6.038 6.066z"
          fill="#ffd064"
        />
        <path
          d="m236.482 306.595 5.585-5.585c17.944-17.943 47.283-17.943 65.227 0s17.944 47.284 0 65.229c-24.719 24.717-44.987 45.354-69.904 69.904-2.721 2.692-5.868 2.664-8.561 0-23.188-22.962-46.83-46.831-69.904-69.904-17.944-17.944-17.944-47.285 0-65.229 17.944-17.943 47.283-17.943 65.227 0l5.584 5.585c2.323 2.324 4.563 2.154 6.746 0z"
          fill="#ff9a9f"
        />
        <path
          d="m259.528 290.097c16.186-5.585 34.896-1.957 47.765 10.913 17.944 17.943 17.944 47.284 0 65.229-24.719 24.717-44.987 45.354-69.904 69.904-2.721 2.692-5.868 2.664-8.561 0-3.6-3.572-7.229-7.145-10.829-10.745 20.183-20.041 37.985-38.127 59.019-59.159 17.944-17.944 17.944-47.285 0-65.229-5.074-5.074-11.083-8.731-17.49-10.913z"
          fill="#ff8086"
        />
      </g>
    </g>
  </Icon>
);

export default SchoolMaterial;
