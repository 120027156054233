import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const PushPin: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 511.836 511.836" {...props}>
    <g>
      <path
        d="m277.356 403.62-68.87 68.771c-2.93 2.93-6.76 4.39-10.6 4.39s-7.68-1.47-10.61-4.4c-5.85-5.859-5.85-15.359.01-21.21l68.87-68.77c5.84-5.84 15.3-5.851 21.15-.05l.06.06c5.86 5.859 5.85 15.359-.01 21.209z"
        fill="#edf5ff"
      />
      <path
        d="m277.356 403.62-68.87 68.771c-2.93 2.93-6.76 4.39-10.6 4.39s-7.68-1.47-10.61-4.4l90.03-90.029.06.06c5.86 5.858 5.85 15.358-.01 21.208z"
        fill="#d5e8fe"
      />
      <path
        d="m446.473 269.862-56.568-56.568c-5.581-5.581-14.533-5.884-20.479-.687l-89.915 78.601c-3.127 2.732-4.98 6.64-5.119 10.79-.14 4.15 1.448 8.173 4.385 11.109l68.59 68.59c2.818 2.818 6.636 4.394 10.605 4.394.188 0 .377-.004.566-.011 4.172-.157 8.089-2.046 10.81-5.212l77.894-90.622c5.114-5.951 4.779-14.836-.769-20.384z"
        fill="#e63950"
      />
      <path
        d="m447.246 290.25-77.9 90.62c-2.72 3.16-6.64 5.05-10.81 5.21-.19.01-.38.01-.56.01-3.97 0-7.79-1.569-10.61-4.39l-34.7-34.7 105.47-105.479 28.34 28.34c5.55 5.549 5.88 14.438.77 20.389z"
        fill="#ae2538"
      />
      <path
        d="m388.006 399.37c0 12.561-4.89 24.36-13.77 33.24l-33.94 33.939c-2.81 2.811-6.63 4.391-10.61 4.391s-7.79-1.58-10.6-4.391l-62.99-62.989-62.17-62.17c-2.81-2.811-4.39-6.63-4.39-10.61 0-3.97 1.58-7.79 4.39-10.6l33.94-33.94c8.88-8.88 20.68-13.77 33.23-13.77 12.56 0 24.36 4.89 33.24 13.77l39.54 39.54 40.36 40.36c8.88 8.88 13.77 20.68 13.77 33.23z"
        fill="#ff7b4a"
      />
      <path
        d="m388.006 399.37c0 12.561-4.89 24.36-13.77 33.24l-33.94 33.939c-2.81 2.811-6.63 4.391-10.61 4.391s-7.79-1.58-10.6-4.391l-62.99-62.989 77.78-77.78 40.36 40.36c8.88 8.88 13.77 20.68 13.77 33.23z"
        fill="#e63950"
      />
      <path
        d="m498.096 297.44c-8.88 8.88-20.68 13.77-33.24 13.77-12.55 0-24.36-4.89-33.23-13.77l-68.59-68.59c-8.88-8.88-13.77-20.681-13.77-33.23 0-12.56 4.89-24.359 13.77-33.24 18.32-18.319 48.14-18.319 66.47 0l68.59 68.591c18.32 18.328 18.32 48.139 0 66.469z"
        fill="#ff7b4a"
      />
      <path
        d="m498.096 297.44c-8.88 8.88-20.68 13.77-33.24 13.77-12.55 0-24.36-4.89-33.23-13.77l-34.7-34.7 66.47-66.47 34.7 34.7c18.32 18.329 18.32 48.14 0 66.47z"
        fill="#e63950"
      />
      <path
        d="m244.372 133.902c-3.836 0-7.671-1.462-10.599-4.386-5.862-5.854-5.869-15.352-.016-21.213l68.749-68.847c5.854-5.862 15.353-5.867 21.213-.016 5.862 5.854 5.869 15.352.016 21.213l-68.749 68.847c-2.93 2.934-6.772 4.402-10.614 4.402z"
        fill="#edf5ff"
      />
      <path
        d="m323.736 60.65-68.75 68.85c-2.93 2.931-6.77 4.4-10.61 4.4s-7.67-1.46-10.6-4.38l-.07-.07 90.01-90.01c5.87 5.85 5.87 15.35.02 21.21z"
        fill="#d5e8fe"
      />
      <path
        d="m233.058 199.512-68.59-68.59c-2.937-2.937-6.982-4.548-11.109-4.385-4.15.139-8.058 1.992-10.79 5.119l-78.601 89.915c-5.196 5.943-4.896 14.896.687 20.479l56.569 56.568c2.918 2.918 6.758 4.394 10.609 4.394 3.473 0 6.954-1.2 9.774-3.625l90.621-77.894c3.166-2.721 5.055-6.639 5.212-10.81.158-4.171-1.43-8.219-4.382-11.171z"
        fill="#ff9100"
      />
      <path
        d="m237.446 210.68c-.16 4.18-2.05 8.09-5.22 10.81l-90.62 77.9c-2.82 2.42-6.3 3.62-9.771 3.62-3.85 0-7.69-1.471-10.61-4.391l-28.34-28.34 105.47-105.47 34.7 34.7c2.951 2.95 4.541 7.001 4.391 11.171z"
        fill="#ff641a"
      />
      <path
        d="m148.801 283.77-68.589-68.59c-18.323-18.325-48.143-18.326-66.468 0-18.325 18.325-18.325 48.143 0 66.468l68.589 68.589c8.877 8.877 20.68 13.767 33.234 13.767s24.357-4.89 33.233-13.766c8.877-8.877 13.767-20.68 13.767-33.234s-4.89-24.358-13.766-33.234z"
        fill="#fed843"
      />
      <path
        d="m162.566 317c0 12.561-4.89 24.36-13.771 33.24-8.87 8.87-20.67 13.76-33.229 13.76-12.55 0-24.36-4.89-33.23-13.76l-34.71-34.71 66.47-66.46 34.7 34.7c8.88 8.88 13.77 20.68 13.77 33.23z"
        fill="#ff9100"
      />
      <path
        d="m317.906 192.44-33.94 33.939c-9.16 9.16-21.19 13.75-33.23 13.75s-24.07-4.58-33.23-13.75l-40.37-40.359-39.54-39.54c-8.87-8.88-13.76-20.681-13.76-33.23 0-12.56 4.89-24.359 13.76-33.239l33.94-33.94c2.82-2.81 6.63-4.39 10.61-4.39s7.79 1.58 10.61 4.39l62.16 62.17 62.99 62.99c5.86 5.858 5.86 15.349 0 21.209z"
        fill="#fed843"
      />
      <path
        d="m317.906 192.44-33.94 33.939c-9.16 9.16-21.19 13.75-33.23 13.75s-24.07-4.58-33.23-13.75l-40.37-40.359 77.78-77.78 62.99 62.99c5.86 5.859 5.86 15.35 0 21.21z"
        fill="#ff9100"
      />
    </g>
  </Icon>
);

export default PushPin;
