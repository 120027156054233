import React from 'react';
// Modules
import {
  Button, Container, Center, Heading, Text, VStack,
} from '@chakra-ui/react';
// Components
import Balloon from '../icon/Balloon';
import Binder from '../icon/Binder';
import Clip from '../icon/Clip';
import CorrectionTape from '../icon/CorrectionTape';
import Pencil from '../icon/Pencil';
import Ruler from '../icon/Ruler';
import SchoolMaterial from '../icon/SchoolMaterial';
import Stationery from '../icon/Stationery';
import PushPin from '../icon/PushPin';
import Scissors from '../icon/Scissors';
import TapeDispenser from '../icon/TapeDispenser';

const Hero: React.FC = () => (
  <Center bgColor="#FEF7E4" h="600px" overflow="hidden" position="relative">
    <Container maxW="container.lg" zIndex="docked">
      <VStack alignItems="flex-start" spacing="8">
        <VStack alignItems="flex-start">
          <Heading as="h1">Utama Stationery</Heading>
          <Text fontSize="lg">
            Alat tulis kantor dan sekolah. Melayani ecer dan grosir.
          </Text>
        </VStack>
        <Button
          as="a"
          colorScheme="primary"
          href="https://shopee.co.id/utamastationerytulungagung"
          rel="noopener noreferrer"
          target="_blank"
          textColor="black"
        >
          Belanja Sekarang
        </Button>
      </VStack>
    </Container>

    <Balloon
      height={['0px', '100px']}
      left={['0px', '1500px']}
      opacity={0.6}
      position="absolute"
      top={['0px', '10px']}
      width={['0px', '100px']}
    />
    <Binder
      height={['60px', '80px']}
      left={['260px', '800px']}
      opacity={0.6}
      position="absolute"
      top={['380px', '380px']}
      transform="rotate(30deg)"
      width={['60px', '80px']}
    />
    <Clip
      height={['0px', '80px']}
      left={['0px', '1620px']}
      opacity={0.6}
      position="absolute"
      top={['0px', '380px']}
      transform="rotate(30deg)"
      width={['0px', '80px']}
    />
    <CorrectionTape
      height={['0px', '80px']}
      left={['0px', '60px']}
      opacity={0.6}
      position="absolute"
      top={['0px', '260px']}
      transform="rotate(-30deg)"
      width={['0px', '80px']}
    />
    <Pencil
      height={['60px', '70px']}
      left={['180px', '460px']}
      opacity={0.6}
      position="absolute"
      top={['40px', '10px']}
      width={['60px', '70px']}
    />
    <Ruler
      height={['60px', '80px']}
      left={['160px', '900px']}
      opacity={0.6}
      position="absolute"
      top={['560px', '140px']}
      transform={['rotate(15deg)', 'rotate(30deg)']}
      width={['60px', '80px']}
    />
    <SchoolMaterial
      height={['0px', '80px']}
      left={['0px', '1100px']}
      opacity={0.6}
      position="absolute"
      top="0px"
      transform="rotate(30deg)"
      width={['0px', '80px']}
    />
    <Stationery
      height={['60px', '80px']}
      left={['20px', '240px']}
      opacity={0.6}
      position="absolute"
      top={['440px', '460px']}
      transform="rotate(30deg)"
      width={['60px', '80px']}
    />
    <PushPin
      height={['60px', '80px']}
      left="-10px"
      opacity={0.6}
      position="absolute"
      top={['-10px', '20px']}
      transform="rotate(30deg)"
      width={['60px', '80px']}
    />
    <Scissors
      height={['60px', '80px']}
      left={['320px', '1200px']}
      opacity={0.6}
      position="absolute"
      top={['120px', '460px']}
      transform="rotate(-30deg)"
      width={['60px', '80px']}
    />
    <TapeDispenser
      height={['0px', '80px']}
      left={['0px', '1300px']}
      opacity={0.6}
      position="absolute"
      top={['0px', '180px']}
      transform="rotate(-30deg)"
      width={['0px', '80px']}
    />
  </Center>
);

export default Hero;
