import React from 'react';
// Modules
import { NextPage } from 'next/types';
// Components
import Hero from '../components/home/Hero';
import Testimonial from '../components/home/Testimonial';
import Page from '../components/layout/Page';

const HomePage: NextPage = () => (
  <Page>
    <Hero />
    <Testimonial />
  </Page>
);

export default HomePage;
