import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const Clip: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="254.8086"
      y1="167.463"
      x2="254.8086"
      y2="8.853"
      gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
    >
      <stop offset="0" stopColor="#4D4D4D" />
      <stop offset="1" stopColor="#333333" />
    </linearGradient>
    <path
      d="M451.791,489.425H60.21L32.768,253.527c-2.303-19.794,13.169-37.171,33.097-37.171h380.27
 c19.928,0,35.4,17.376,33.097,37.171L451.791,489.425z"
      fill="url(#SVGID_1_)"
    />
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="439.4891"
      y1="50.613"
      x2="216.5491"
      y2="273.553"
      gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
    >
      <stop offset="0" stopColor="#4D4D4D" stopOpacity="0" />
      <stop offset="1" stopColor="#000000" />
    </linearGradient>
    <polygon
      points="466.642,361.762 322.107,217.227 306.851,217.227 335.855,489.425 451.791,489.425
 "
      fill="url(#SVGID_2_)"
    />
    <linearGradient
      id="SVGID_3_"
      gradientUnits="userSpaceOnUse"
      x1="293.4879"
      y1="51.5118"
      x2="108.4479"
      y2="236.5618"
      gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
    >
      <stop offset="0" stopColor="#4D4D4D" stopOpacity="0" />
      <stop offset="1" stopColor="#000000" />
    </linearGradient>
    <path
      d="M340.155,489.425L320.2,318.292L218.264,216.357h-13.142
 c-0.615,8.756-11.699,165.165-21.583,219.528c-5.379,29.587-21.784,45.3-36.047,53.54H340.155z"
      fill="url(#SVGID_3_)"
    />
    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="254.8086"
      y1="466.583"
      x2="254.8086"
      y2="-128.967"
      gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
    >
      <stop offset="0" stopColor="#E6E6E6" />
      <stop offset="1" stopColor="#999999" />
    </linearGradient>
    <path
      d="M381.856,504.807c-33.563,0-61.566-25.166-65.135-58.539l-22.502-210.353
 c-2.556-23.891,3.139-48.212,16.04-68.485l11.973-18.816c21.419-33.66,14.202-77.994-16.789-103.122
 c-28.65-23.228-70.235-23.23-98.884,0c-30.991,25.128-38.208,69.46-16.79,103.122l11.973,18.816
 c12.9,20.271,18.596,44.593,16.04,68.485l-22.502,210.352c-3.569,33.372-31.572,58.539-65.135,58.539v-27.968
 c19.234,0,35.28-14.421,37.326-33.546l22.502-210.353c1.884-17.616-2.315-35.548-11.826-50.494l-11.973-18.816
 c-29.05-45.652-19.261-105.779,22.77-139.86C207.848,8.441,231.662,0,256.001,0s48.152,8.441,67.056,23.769
 c42.032,34.081,51.822,94.208,22.771,139.86l-11.973,18.816c-9.511,14.946-13.711,32.879-11.826,50.494l22.502,210.353
 c2.046,19.125,18.092,33.546,37.326,33.546V504.807z"
      fill="url(#SVGID_4_)"
    />
    <g>
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="92.1456"
        y1="36.083"
        x2="92.1456"
        y2="5.873"
        gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
      >
        <stop offset="0" stopColor="#4D4D4D" />
        <stop offset="1" stopColor="#333333" />
      </linearGradient>
      <path
        d="M127.297,512H58.101c-11.465,0-20.759-9.294-20.759-20.759l0,0
   c0-11.465,9.294-20.759,20.759-20.759h69.195c11.465,0,20.759,9.294,20.759,20.759l0,0C148.056,502.706,138.762,512,127.297,512z"
        fill="url(#SVGID_5_)"
      />

      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="254.8096"
        y1="36.083"
        x2="254.8096"
        y2="5.873"
        gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
      >
        <stop offset="0" stopColor="#4D4D4D" />
        <stop offset="1" stopColor="#333333" />
      </linearGradient>
      <path
        d="M290.599,512h-69.195c-11.465,0-20.759-9.294-20.759-20.759l0,0
   c0-11.465,9.294-20.759,20.759-20.759h69.195c11.465,0,20.759,9.294,20.759,20.759l0,0C311.357,502.706,302.063,512,290.599,512z"
        fill="url(#SVGID_6_)"
      />

      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="417.4726"
        y1="36.083"
        x2="417.4726"
        y2="5.873"
        gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
      >
        <stop offset="0" stopColor="#4D4D4D" />
        <stop offset="1" stopColor="#333333" />
      </linearGradient>
      <path
        d="M453.9,512h-69.195c-11.465,0-20.759-9.294-20.759-20.759l0,0
   c0-11.465,9.294-20.759,20.759-20.759H453.9c11.465,0,20.759,9.294,20.759,20.759l0,0C474.659,502.706,465.364,512,453.9,512z"
        fill="url(#SVGID_7_)"
      />
    </g>
    <radialGradient
      id="SVGID_8_"
      cx="255.8091"
      cy="410.853"
      r="99.5"
      gradientTransform="matrix(1.0039 0 0 -1.0039 0.1922 516.5609)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#666666" />
      <stop offset="0.428" stopColor="#686868" stopOpacity="0.572" />
      <stop offset="0.582" stopColor="#6F6F6F" stopOpacity="0.418" />
      <stop offset="0.692" stopColor="#7A7A7A" stopOpacity="0.308" />
      <stop offset="0.781" stopColor="#8B8B8B" stopOpacity="0.219" />
      <stop offset="0.857" stopColor="#A1A1A1" stopOpacity="0.143" />
      <stop offset="0.924" stopColor="#BDBDBD" stopOpacity="0.076" />
      <stop offset="0.984" stopColor="#DCDCDC" stopOpacity="0.016" />
      <stop offset="1" stopColor="#E6E6E6" stopOpacity="0" />
    </radialGradient>
    <path
      d="M310.259,167.431c-6.234,9.797-10.769,20.545-13.52,31.729
 c23.188-9.064,42.609-25.638,55.28-46.732c21.062-43.896,9.713-97.3-28.963-128.659C304.153,8.441,280.339,0,256.001,0
 s-48.152,8.441-67.057,23.769c-38.675,31.36-50.023,84.765-28.961,128.662c12.671,21.092,32.092,37.665,55.279,46.73
 c-2.751-11.184-7.284-21.931-13.52-31.728l-11.973-18.816c-21.419-33.661-14.201-77.994,16.79-103.122
 c28.649-23.23,70.235-23.228,98.884,0c30.991,25.128,38.207,69.461,16.789,103.122L310.259,167.431z"
      fill="url(#SVGID_8_)"
    />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Icon>
);

export default Clip;
