import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const CorrectionTape: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <path
      d="M368,112c-45.806,0-91.456,10.648-135.682,31.648c-37.566,17.838-73.17,42.583-105.823,73.547
 C65.118,275.397,32,338.333,32,368c0,17.645,14.355,32,32,32h304c79.402,0,144-64.599,144-144S447.402,112,368,112z"
      fill="#68DEC8"
    />
    <path
      d="M64,376c-4.411,0-8-3.589-8-8c0-8.071,5.458-26.638,20.838-52.398
 c11.453-19.183,32.23-48.806,66.171-80.991c30.815-29.221,64.326-52.53,99.604-69.282C283.598,145.867,325.784,136,368,136
 c66.168,0,120,53.832,120,120s-53.832,120-120,120H64z"
      fill="#38C7C2"
    />
    <path
      d="M144,376H8c-4.418,0-8-3.582-8-8s3.582-8,8-8h136c54.713,0,72.039-26.677,113.898-91.126
 c14.47-22.279,32.479-50.006,55.651-81.604c2.613-3.563,7.62-4.334,11.182-1.721c3.563,2.612,4.333,7.619,1.72,11.182
 c-22.904,31.233-40.027,57.598-55.135,80.858c-21.385,32.927-36.837,56.717-55.19,73.091C196.499,368.19,174.254,376,144,376z"
      fill="#E1E0E4"
    />
    <path
      d="M368,168c-48.523,0-88,39.477-88,88s39.477,88,88,88s88-39.477,88-88S416.523,168,368,168z"
      fill="#F0F0F1"
    />
    <path
      d="M368,194c-34.187,0-62,27.813-62,62s27.813,62,62,62s62-27.813,62-62S402.187,194,368,194z"
      fill="#E1E0E4"
    />
    <circle cx="368" cy="256" fill="#787485" r="38" />
    <path
      d="M368,210c-25.364,0-46,20.636-46,46s20.636,46,46,46s46-20.636,46-46S393.364,210,368,210z
  M376,284.908V275c0-4.418-3.582-8-8-8s-8,3.582-8,8v9.908c-10.12-2.804-18.104-10.788-20.908-20.908H349c4.418,0,8-3.582,8-8
 s-3.582-8-8-8h-9.908c2.804-10.121,10.788-18.104,20.908-20.908V237c0,4.418,3.582,8,8,8s8-3.582,8-8v-9.908
 c10.12,2.804,18.104,10.788,20.908,20.908H387c-4.418,0-8,3.582-8,8s3.582,8,8,8h9.908C394.104,274.121,386.12,282.104,376,284.908z
 "
      fill="#AD7DC9"
    />
    <path
      d="M152,280c-26.467,0-48,21.532-48,48s21.533,48,48,48s48-21.532,48-48S178.467,280,152,280z"
      fill="#F082A6"
    />
    <path
      d="M152,302.5c-14.061,0-25.5,11.439-25.5,25.5s11.439,25.5,25.5,25.5s25.5-11.439,25.5-25.5
 S166.061,302.5,152,302.5z"
      fill="#E6608D"
    />
    <circle cx="152" cy="328" fill="#787485" r="16" />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Icon>
);

export default CorrectionTape;
