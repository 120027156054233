import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const Ruler: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <path
      d="M504.451,444.292l-30.137,30.147l-29.959,29.959c-10.136,10.136-26.709,10.136-36.845,0L7.603,104.491c-10.136-10.136-10.136-26.72,0-36.856l29.959-29.959L67.636,7.602c10.147-10.136,26.72-10.136,36.856,0l399.907,399.896C514.514,417.624,514.534,434.156,504.451,444.292z"
      fill="#F89E00"
    />
    <path
      d="M504.451,444.292l-30.137,30.147L37.561,37.676L67.635,7.602c10.147-10.136,26.72-10.136,36.856,0l399.907,399.896C514.514,417.624,514.534,434.156,504.451,444.292z"
      fill="#FCBA4F"
    />
    <g>
      <path
        d="M62.215,63.705L62.215,63.705c-3.061-3.062-3.061-8.023-0.001-11.084l43.65-43.65l11.083,11.084l-43.648,43.65C70.238,66.766,65.275,66.766,62.215,63.705z"
        fill="#473E51"
      />
      <path
        d="M111.778,65.568L111.778,65.568c-3.062-3.062-3.061-8.023-0.001-11.084l19.8-19.799l11.084,11.084l-19.799,19.799C119.801,68.629,114.839,68.629,111.778,65.568z"
        fill="#473E51"
      />
      <path
        d="M165.068,166.559L165.068,166.559c-3.062-3.062-3.061-8.023-0.001-11.084l43.65-43.649l11.083,11.084l-43.649,43.649C173.091,169.619,168.129,169.619,165.068,166.559z"
        fill="#473E51"
      />
      <path
        d="M163.205,116.995L163.205,116.995c-3.061-3.061-3.061-8.022,0-11.083l19.799-19.8l11.083,11.082l-19.799,19.8C171.228,120.056,166.266,120.056,163.205,116.995z"
        fill="#473E51"
      />
      <path
        d="M137.492,91.282L137.492,91.282c-3.062-3.062-3.061-8.023-0.001-11.084l19.8-19.799l11.083,11.084l-19.799,19.799C145.514,94.342,140.552,94.342,137.492,91.282z"
        fill="#473E51"
      />
      <path
        d="M214.632,168.422L214.632,168.422c-3.061-3.062-3.061-8.023-0.001-11.084l19.8-19.8l11.083,11.084l-19.799,19.8C222.655,171.482,217.693,171.482,214.632,168.422z"
        fill="#473E51"
      />
      <path
        d="M267.923,269.412l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l43.649-43.649l11.084,11.084l-43.649,43.649C275.946,272.473,270.984,272.473,267.923,269.412z"
        fill="#473E51"
      />
      <path
        d="M266.06,219.85l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.799-19.799l11.084,11.084l-19.799,19.799C274.082,222.909,269.12,222.909,266.06,219.85z"
        fill="#473E51"
      />
      <path
        d="M240.345,194.135l-0.001-0.001c-3.06-3.061-3.06-8.022,0-11.083l19.799-19.799l11.084,11.084l-19.799,19.799C248.368,197.196,243.406,197.196,240.345,194.135z"
        fill="#473E51"
      />
      <path
        d="M317.486,271.276l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.799-19.799l11.084,11.084l-19.799,19.799C325.509,274.336,320.547,274.336,317.486,271.276z"
        fill="#473E51"
      />
      <path
        d="M370.776,372.267l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l43.649-43.649l11.084,11.084l-43.649,43.649C378.799,375.327,373.837,375.327,370.776,372.267z"
        fill="#473E51"
      />
      <path
        d="M368.913,322.702l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.799-19.799l11.084,11.084l-19.799,19.799C376.935,325.763,371.974,325.763,368.913,322.702z"
        fill="#473E51"
      />
      <path
        d="M343.2,296.99l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.8-19.8l11.084,11.084l-19.8,19.8C351.222,300.05,346.259,300.05,343.2,296.99z"
        fill="#473E51"
      />
      <path
        d="M420.34,374.13l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.8-19.8l11.084,11.084l-19.8,19.8C428.362,377.191,423.401,377.191,420.34,374.13z"
        fill="#473E51"
      />
      <path
        d="M471.767,425.557l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.8-19.8l11.084,11.084l-19.8,19.8C479.789,428.617,474.826,428.617,471.767,425.557z"
        fill="#473E51"
      />
      <path
        d="M446.053,399.843l-0.001-0.001c-3.061-3.061-3.061-8.022,0-11.083l19.799-19.799l11.084,11.084l-19.799,19.799C454.075,402.903,449.114,402.903,446.053,399.843z"
        fill="#473E51"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </Icon>
);

export default Ruler;
