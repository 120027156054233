import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const TapeDispenser: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <path
      d="m456 207.687h-120a8 8 0 0 1 0-16h118.881c4.487-1.4 25.119-9.071 25.119-32a8 8 0 0 1 16 0c0 30.252-24.9 44.469-38.06 47.76a7.978 7.978 0 0 1 -1.94.24z"
      fill="#fae8d8"
    />
    <path
      d="m224 87.69a112 112 0 1 0 112 112 111.995 111.995 0 0 0 -112-112zm0 144a32 32 0 1 1 32-32 32 32 0 0 1 -32 32z"
      fill="#fae8d8"
    />
    <path
      d="m224 135.69a64 64 0 1 0 64 64 64 64 0 0 0 -64-64zm0 96a32 32 0 1 1 32-32 32 32 0 0 1 -32 32z"
      fill="#e2d2c2"
    />
    <path
      d="m24 375.687c0-128 56-176 56-176h144l72 64h80l38.626-72h41.374v184l-208 32z"
      fill="#3a98e4"
    />
    <path d="m456 375.69-32 48h-368l-32-48z" fill="#3a98e4" />
    <g fill="#3385c8">
      <path d="m456 375.69-32 48h-368l-32-48z" />
      <path d="m72 351.687a8 8 0 0 1 -8-8c0-91.478 42.092-117.786 43.884-118.861a8 8 0 0 1 8.292 13.684c-.277.178-9.156 5.965-17.946 21.381-15.067 26.429-18.23 60.156-18.23 83.796a8 8 0 0 1 -8 8z" />
    </g>
  </Icon>
);

export default TapeDispenser;
