import React from 'react';
// Modules
import { StarIcon } from '@chakra-ui/icons';
import {
  Container, Heading, SimpleGrid, Text, VStack, Box, HStack,
} from '@chakra-ui/react';
import Image from 'next/image';

const items = [
  {
    author: 'Eka Dewa Purnama Putra',
    rating: 5,
    text: 'Tujuan pertama ketika mencari alat tulis menulis...',
  },
  {
    author: 'Vanisha Amelia',
    rating: 5,
    text: 'This place is one of the best stationery in Tulungagung.',
  },
  {
    author: 'Nanang Abdul Aziz Kahar',
    rating: 5,
    text: 'Toko perlengkapan ATK cukup lengkap d tulungagung',
  },
  {
    author: 'Ninda Dwi Rahayu',
    rating: 5,
    text: 'Bagus, lengkap, harga terjangkau... Cocok untuk beli grosir...',
  },
  {
    author: 'BangSBT',
    rating: 5,
    text: 'Sangat Mantap dan murah sekali',
  },
  {
    author: 'Anisha Wulandari',
    rating: 5,
    text: 'Kebutuhan ATK sangat lengkap',
  },
  {
    author: 'Ardiansah Sulistyo',
    rating: 5,
    text: 'Toko ATK yg lengkap harga murah',
  },
  {
    author: 'Rahayu Sektiani',
    rating: 5,
    text: 'Pelayanannya baik',
  },
  {
    author: 'Candra Wayang',
    rating: 5,
    text: 'MOURAHH POLLL,,,kualitas sama harga beda.',
  },
];

const Testimonial: React.FC = () => (
  <Box bgColor="secondary.500">
    <Container maxW="container.lg" py="16">
      <Heading color="white" mb="16" size="lg" textAlign="center">
        Testimoni
      </Heading>
      <SimpleGrid columns={[0, 3]} spacing="8">
        {items.map((item) => (
          <Box key={item.author} bgColor="white" borderRadius="md" p="4" shadow="lg">
            <VStack alignItems="center">
              <Box display="flex" mb="4">
                <Image height={24} src="/images/left-quotes-sign.svg" width={24} />
              </Box>
              <Text h="48px" textAlign="center">{item.text}</Text>
              <Text fontSize="sm" opacity={0.5} textAlign="center">{`- ${item.author} -`}</Text>
              <HStack alignItems="center" justifyContent="center">
                {Array.from(new Array(item.rating).keys()).map((rating) => (
                  <StarIcon key={rating} color="primary.500" />
                ))}
              </HStack>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  </Box>
);

export default Testimonial;
