import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const Stationery: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                d="m434.543 175.495c-.925 0-1.865-.167-2.78-.521-3.978-1.536-5.956-6.006-4.42-9.984l21.033-54.452c.877-2.27-.249-4.818-2.521-5.695l-32.726-12.642c-3.978-1.536-5.956-6.006-4.42-9.984 1.536-3.977 6.003-5.958 9.983-4.42l32.727 12.642c10.233 3.953 15.313 15.43 11.36 25.663l-21.033 54.452c-1.183 3.063-4.105 4.941-7.203 4.941z"
                fill="#e0dde0"
              />
            </g>
            <g>
              <g>
                <path
                  d="m430.585 70.578-34.845-13.46 19.184-49.663c2.09-5.409 8.169-8.101 13.578-6.011l15.256 5.893c5.409 2.09 8.101 8.169 6.011 13.578z"
                  fill="#e0dde0"
                />
                <g fill="#e8ae4d">
                  <path d="m376.853 84.024-3.054 2.089-105.077 272.02 53.538 20.681 105.076-272.02-1.708-3.929z" />
                  <path d="m441.801 41.861-35.06-13.543c-5.102-1.971-10.836.568-12.808 5.67l-8.451 21.878 1.866 3.99 47.788 18.46 3.884-1.769 8.451-21.878c1.971-5.103-.567-10.837-5.67-12.808z" />
                </g>
              </g>
            </g>
            <path
              d="m390.197 52.633h32.426v57.393h-32.426z"
              fill="#edeaed"
              transform="matrix(.36 -.933 .933 .36 184.101 431.132)"
            />
          </g>
          <g>
            <g fill="#9bc4f9">
              <path d="m227.64 96.977-9.368 4.435-6.129 264.463 59.378 1.376 6.13-264.463-9.147-5.058z" />
              <path d="m269.069 73.365 9.369-4.512 1.107-47.746c.258-11.152-8.573-20.403-19.725-20.661l-18.992-.44c-11.152-.259-20.403 8.573-20.661 19.725l-1.107 47.746 9.145 5.136z" />
            </g>
            <path
              d="m231.383 55.435h33.945v59.395h-33.945z"
              fill="#edeaed"
              transform="matrix(.023 -1 1 .023 157.491 331.448)"
            />
          </g>
          <g>
            <g>
              <path
                d="m112.405 58.355v-.001.001l-6.901-.931-47.164 21.724-3.735 5.83-.001-.001.001.001 152.982 332.14 57.8-26.622z"
                fill="#e07f84"
              />
            </g>
            <path
              d="m54.605 84.978 57.8-26.623c0-.001 0-.001-.001-.002-2.463-5.348-5.947-10.163-10.255-14.176l-45.039-41.956c-3.592-3.346-9.433-.655-9.225 4.249l2.611 61.499c.25 5.883 1.645 11.66 4.109 17.009 0-.001 0 0 0 0z"
              fill="#89808c"
            />
            <path
              d="m243.499 400.577-152.981-332.14-14.025 6.46 152.982 332.14c1.303 2.827 4.096 4.493 7.018 4.493 1.081 0 2.178-.228 3.225-.71 3.871-1.785 5.565-6.37 3.781-10.243z"
              fill="#d84a54"
            />
          </g>
        </g>
        <g>
          <path
            d="m303.957 481.312 42.294-274.334h-266.956l42.294 274.334c2.722 17.657 17.916 30.688 35.781 30.688h110.805c17.865 0 33.059-13.031 35.782-30.688z"
            fill="#d1ccd3"
          />
          <path
            d="m342.648 206.978-42.294 274.334c-2.722 17.657-17.917 30.688-35.782 30.688h65.195c17.865 0 33.059-13.031 35.781-30.688l42.294-274.334z"
            fill="#c1bcc4"
          />
          <path
            d="m409.186 245.292h-331.234c-13.098 0-23.716-10.618-23.716-23.716 0-13.098 10.618-23.716 23.716-23.716h331.234c13.098 0 23.716 10.618 23.716 23.716 0 13.098-10.618 23.716-23.716 23.716z"
            fill="#f7f4f7"
          />
          <path
            d="m409.186 197.86h-39.548v23.716c0 13.098-10.618 23.716-23.716 23.716h63.265c13.098 0 23.716-10.618 23.716-23.716-.001-13.098-10.619-23.716-23.717-23.716z"
            fill="#edeaed"
          />
        </g>
      </g>
      <path
        d="m330.758 307.469h-77.861c-9.063 0-16.41 7.347-16.41 16.41v81.442c0 9.063 7.347 16.41 16.41 16.41h60.245z"
        fill="#f4e066"
      />
      <path d="m309.539 421.732h65.195l17.616-114.263h-65.195z" fill="#efd133" />
    </g>
  </Icon>
);

export default Stationery;
