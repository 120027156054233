import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const Balloon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 460.092 460.092" {...props}>
    <g>
      <path
        d="m159.112 460.092c-8.577-21.446-6.195-45.723 6.387-65.093 1.988-3.003 4.23-5.879 6.387-8.713 4.838-5.493 8.648-11.811 11.251-18.652 3.468-11.124 0-23.601-3.003-31.975-.888-2.538-1.861-5.033-2.834-7.571-3.447-8.129-5.877-16.653-7.233-25.377-1.227-10.785 1.353-24.87 12.266-32.229l2.284 3.426c-9.347 6.302-11.504 19.202-10.447 28.338 1.313 8.355 3.672 16.512 7.021 24.278.973 2.58 1.903 5.118 2.834 7.698 3.13 8.924 6.894 22.247 3.045 34.556-2.766 7.338-6.836 14.115-12.012 20.006-2.115 2.749-4.23 5.583-6.217 8.459-11.765 18.239-13.971 41.045-5.921 61.202z"
        fill="#3a556a"
      />
      <path
        d="m176.665 82.519c-44.791 0-81.038 41.619-81.038 93.05s36.247 93.05 81.038 93.05 81.038-41.619 81.038-93.05-36.12-93.05-81.038-93.05z"
        fill="#fcd462"
      />
      <path
        d="m237.317 113.902c4.136 11.474 6.227 23.585 6.175 35.782 0 51.305-36.29 93.05-80.996 93.05-24.063-.417-46.548-12.059-60.779-31.468 12.139 33.836 41.111 57.48 74.948 57.48 44.749 0 81.038-41.619 81.038-93.05.242-22.288-6.929-44.025-20.386-61.794z"
        fill="#efc84a"
      />
      <path d="m174.296 261.429-11.8 31.257h28.338l-8.882-33.16z" fill="#efc84a" />
      <path
        d="m151.668 94.7s-53.715 24.997-45.383 93.939c0 .126-4.61-63.74 45.383-93.939z"
        fill="#ebf0f3"
      />
      <path
        d="m185.166 267.773h-14.719c-.794 0-1.438.644-1.438 1.438s.644 1.438 1.438 1.438h14.719c.794 0 1.438-.644 1.438-1.438s-.644-1.438-1.438-1.438z"
        fill="#3a556a"
      />
      <path
        d="m265.866 377.573c-8.523-21.478-6.112-45.754 6.471-65.135 1.988-3.003 4.23-5.921 6.387-8.713 4.827-5.501 8.611-11.836 11.166-18.695 3.468-11.124 0-23.601-3.003-31.975-.888-2.538-1.861-5.033-2.792-7.571-3.48-8.12-5.924-16.646-7.275-25.377-1.184-10.785 1.396-24.912 12.266-32.229l2.326 3.426c-9.39 6.302-11.504 19.202-10.489 28.338 1.353 8.346 3.71 16.498 7.021 24.278.973 2.58 1.946 5.118 2.834 7.698 3.13 8.924 6.894 22.247 3.045 34.555-2.725 7.33-6.752 14.107-11.885 20.006-2.115 2.749-4.23 5.583-6.217 8.459-11.835 18.239-14.09 41.085-6.048 61.286z"
        fill="#3a556a"
      />
      <path
        d="m283.419 0c-44.749 0-81.039 41.619-81.039 93.05s36.29 93.05 81.038 93.05 81.038-41.619 81.038-93.05-36.289-93.05-81.037-93.05z"
        fill="#e56353"
      />
      <path
        d="m344.24 31.256c4.075 11.401 6.149 23.421 6.133 35.528 0 51.347-36.247 93.05-80.996 93.05-24.083-.318-46.626-11.903-60.906-31.299 12.139 33.836 41.154 57.522 74.948 57.522 44.791 0 81.038-41.619 81.038-93.05.285-22.254-6.826-43.973-20.217-61.751z"
        fill="#d15241"
      />
      <path d="m281.05 178.91-11.8 31.257h28.338l-8.84-33.16z" fill="#d15241" />
      <path
        d="m258.422 12.308s-53.758 24.997-45.299 93.939c0 0-4.694-63.867 45.299-93.939z"
        fill="#ebf0f3"
      />
      <path
        d="m291.92 185.255h-14.677c-.794 0-1.438.644-1.438 1.438s.644 1.438 1.438 1.438h14.677c.794 0 1.438-.644 1.438-1.438s-.644-1.438-1.438-1.438z"
        fill="#3a556a"
      />
    </g>
  </Icon>
);

export default Balloon;
