import React from 'react';
// Modules
import { Icon, IconProps } from '@chakra-ui/react';

const Pencil: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        d="m13.678 455.323-11.036 21.591c-4.759 9.311-2.974 20.629 4.42 28.023 7.394 7.394 18.712 9.179 28.023 4.42l21.591-11.036z"
        fill="#26457d"
      />
      <path
        d="m337.293 85.832h140.33v37.421h-140.33z"
        fill="#e4fbef"
        transform="matrix(.707 .707 -.707 .707 193.264 -257.497)"
      />
      <path
        d="m435.112 126.35h25.727v37.421h-25.727z"
        fill="#d3effb"
        transform="matrix(.707 .707 -.707 .707 233.782 -274.28)"
      />
      <path
        d="m138.693 470.803c-.855.515-1.731.999-2.617 1.442-1.885.968-3.853 1.782-5.882 2.431-.041.021-.072.031-.113.041l-73.4 23.601-43-43 23.601-73.4c.01-.041.021-.072.041-.113.649-2.029 1.463-3.997 2.431-5.893.443-.886.927-1.751 1.442-2.606z"
        fill="#f1d59d"
      />
      <path
        d="m470.302 140.926-99.228-99.228 31.422-31.422c13.701-13.701 35.914-13.701 49.614 0l49.614 49.614c13.701 13.701 13.701 35.914 0 49.614z"
        fill="#df75a5"
      />
      <path
        d="m501.725 59.889-18.192-18.192c13.701 13.701 13.701 35.914 0 49.614l-31.422 31.422 18.192 18.192 31.422-31.422c13.7-13.7 13.7-35.913 0-49.614z"
        fill="#dd5790"
      />
      <path
        d="m377.685 101.236-304.274 304.285-4.77 4.759c-2.029-2.03-4.986-3.142-8.149-2.617l-13.856 2.308c-6.325 1.061-11.816-4.43-10.765-10.765l3.884-23.292c.443-.886.927-1.751 1.442-2.606 1.72-2.864 3.801-5.532 6.181-7.912l297.238-297.239z"
        fill="#ffe07d"
      />
      <path
        d="m443.843 167.384-297.238 297.238c-2.38 2.38-5.048 4.461-7.912 6.181-.855.515-1.731.999-2.617 1.442l-23.282 3.884c-6.336 1.051-11.826-4.44-10.765-10.765l2.308-13.856c.525-3.163-.587-6.119-2.617-8.149l4.77-4.759 304.274-304.285z"
        fill="#ffc250"
      />
      <path
        d="m377.689 101.235-309.045 309.045c2.032 2.032 3.14 4.987 2.613 8.153l-2.309 13.853c-1.055 6.332 4.434 11.821 10.766 10.766l13.853-2.309c3.166-.528 6.121.581 8.153 2.613l309.045-309.045z"
        fill="#ffd064"
      />
    </g>
  </Icon>
);

export default Pencil;
